#testInfo .exportdialog .el-dialog__title {
  font-size: 16px;
}
#testInfo .exportdialog .export-box {
  width: 100%;
  margin-bottom: 20px;
}
#testInfo .exportdialog .export-box h1 {
  padding: 0 5px 10px;
  display: flex;
  align-items: flex-end;
  font-size: 40px;
  border-bottom: 1px dashed #ccc;
}
#testInfo .exportdialog .export-box h1 span {
  margin-left: 20px;
  font-size: 14px;
}
#testInfo .exportdialog .export-box > div {
  padding-left: 30px;
}
#testInfo .exportdialog .export-box .el-button.is-disabled,
#testInfo .exportdialog .export-box .el-button.is-disabled:focus,
#testInfo .exportdialog .export-box .el-button.is-disabled:hover {
  color: #fff;
  background: #c3c3c3;
  border: none;
}
#testInfo .exportdialog .el-form-item {
  display: flex;
  align-items: center;
}
#testInfo .exportdialog .upload-workers {
  height: 30px;
}
#testInfo .exportdialog .upload-workers .el-upload {
  height: 30px !important;
  width: 97px;
  border: none !important;
}
#testInfo .exportdialog .upload-workers .el-upload .el-button {
  padding: 5px 0;
}
#testInfo .el-textarea__inner {
  min-height: 100px !important;
}
#testInfo .studentMation {
  display: flex;
  flex-wrap: wrap;
}
#testInfo .studentMation .el-form-item {
  width: 50%;
  margin-bottom: 5px;
}



















































































































































































































































































































































































































































































































































































































































































































































































































































#testInfo {
  .exportdialog {
    .el-dialog__title {
      font-size: 16px;
    }
    .export-box {
      width: 100%;
      margin-bottom: 20px;
      h1 {
        padding: 0 5px 10px;
        display: flex;
        align-items: flex-end;
        font-size: 40px;
        border-bottom: 1px dashed #ccc;
        span {
          margin-left: 20px;
          font-size: 14px;
        }
      }
      > div {
        padding-left: 30px;
      }
      .el-button.is-disabled,
      .el-button.is-disabled:focus,
      .el-button.is-disabled:hover {
        color: #fff;
        background: #c3c3c3;
        border: none;
      }
    }
    .el-form-item {
      display: flex;
      align-items: center;
    }
  }
  .exportdialog {
    .upload-workers {
      height: 30px;
      .el-upload {
        height: 30px !important;
        width: 97px;
        border: none !important;
        .el-button {
          padding: 5px 0;
        }
      }
    }
  }
  .el-textarea__inner {
    min-height: 100px !important;
  }
  .studentMation {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 50%;
      margin-bottom: 5px;
    }
  }
}
